import * as React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import "@fontsource/league-spartan/600.css"

const IndexPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "bg-desktop.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  const imageData = data.desktop.childImageSharp.fluid

  return (
    <BackgroundImage
      Tag="section"
      fluid={imageData}
      backgroundColor={`#040e18`}
    >
      <div className="flex h-screen justify-center items-center">
        <div className="text-centre text-black primary-font p-8 bg-slate-200 bg-opacity-60">
          <div className="text-2xl">Now in development...</div>
          <div className="text-center">
            <div className="text-6xl font-extrabold mt-8">Magic Menu Maker</div>
            <div className="text-xl">the fastest menu making and management App</div>
            <div className="mt-8 flex flex-col items-center text-lg">
              <div className="">Take our 2 minute survey and join the fun!</div>
              <button className="bg-green-400 hover:bg-green-500 p-4 rounded-full w-64"> 
                <a href="http://www.survey.magicmenumaker.com/">Start Survey</a>
              </button>
              <div>www.magicmenumaker.com</div>
            </div>
          </div>
        </div>
      </div>
      </BackgroundImage>
  )
}

export default IndexPage
